import './App.css';
import mobileGuide from './assets/guia/mobile.png';
import desktopGuide from './assets/guia/desktop.png';
import background from './assets/2 Web/1 Fondo.png';
import Grid from './components/Grid/Grid';
import DesktopGrid from './components/DesktopGrid/DesktopGrid';

// Logos
import logo1 from './assets/1 Mobile/Logo 1.png';
import logo2 from './assets/1 Mobile/Logo 2.png';
import logo3 from './assets/1 Mobile/Logo 3.png';

// Social Icons
import instagram_icon from './assets/1 Mobile/Btn Instagram.png';
import facebook_icon from './assets/1 Mobile/Btn Facebook.png';
import linkedin_icon from './assets/1 Mobile/Btn Linkedin.png';
import tiktok_icon from './assets/1 Mobile/Btn Tiktok.png';
import youtube_icon from './assets/1 Mobile/Btn Youtube.png';

// Other UI Elements
import mobileTitle from './assets/1 Mobile/2 Titulo.png';
import desktopTitle from './assets/2 Web/2 Titulo.png';
import greenCircle from './assets/1 Mobile/Circulo verde.png';

import { useEffect, useState } from 'react';
import { GetImages } from './data/GetImages';
import Popup from './components/Popup/Popup';

function App() {
  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showingPopup, setShowingPopup] = useState(false);

  const SetImagesFromDrive = async () => {
    const _images = await GetImages();
    setImages(_images);
  }

  useEffect(() => {
    SetImagesFromDrive();
  }, []);

  useEffect(()=>{
    if(selectedImage) {
      setShowingPopup(true);
    }
  }, [selectedImage]);

  const Content = () => {
    return (
      <>
        {/* <img src={mobileGuide} alt='mobile guide' className='referenceImg mobile' />
        <img src={desktopGuide} alt='desktop guide' className='referenceImg desktop' /> */}
        <img src={background} alt='background' className='bckImg' />
        <div className='logosDiv'>
          <img className='logo1' src={logo1} alt='logo1' />
          <img className='logo2' src={logo2} alt='logo1' />
          <img className='logo3' src={logo3} alt='logo1' />
        </div>
        <img className='title mobile' src={mobileTitle} alt='title' /> 
        <img className='title desktop' src={desktopTitle} alt='title' /> 
        <Grid setSelectedImage={setSelectedImage} images={images}/>
        <DesktopGrid setSelectedImage={setSelectedImage} images={images}/>
        <div className='socialIcons'>
          <img
            className='socialIcon'
            src={instagram_icon}
            alt='social icon'
            onClick={() => {
              window.open('https://instagram.com/empaquetadurasdarrow', '_blank');
            }}
          />
          <img
            className='socialIcon'
            src={facebook_icon}
            alt='social icon'
            onClick={() => {
              window.open('https://www.facebook.com/DARROWSAS', '_blank');
            }}
          />
          <img
            className='socialIcon'
            src={linkedin_icon}
            alt='social icon'
            onClick={() => {
              window.open('https://www.linkedin.com/company/darrowempaques/', '_blank');
            }}
          />
          <img
            className='socialIcon'
            src={tiktok_icon}
            alt='social icon'
            onClick={() => {
              window.open('https://www.tiktok.com/@darrowsas', '_blank');
            }}
          />
          <img
            className='socialIcon'
            src={youtube_icon}
            alt='social icon'
            onClick={() => {
              window.open('https://youtube.com/@darrow-solucionesparatumot9008', '_blank');
            }}
          />
        </div>
        <p className='darrowUrl'>WWW.DARROW.COM.CO</p>
        <img src={greenCircle} alt='green cirle' className='greenCircle' />
      </>
    )
  }

  return (
    <div className="App">
      <Content />
      {
        showingPopup &&
        <Popup
          selectedImage={selectedImage}
          closeAction={() => {
            setSelectedImage(null);
            setShowingPopup(false);
          }}
        />
      }
    </div>
  );
}

export default App;
