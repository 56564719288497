import React from 'react';
import './DesktopGrid.css';

const DesktopGrid = ({ images, setSelectedImage }) => {
  if(images) {
    if(images.length>0) {
      const numRows = Math.round((images.length/3)+1);
      const numCols = 6;
      const numCells = numRows * numCols;
      const displayedImages = images.slice(0, numCells);
    
      const rows = [];
      for (let i = 0; i < numRows; i++) {
        const cells = [];
        for (let j = 0; j < numCols; j++) {
          const index = i * numCols + j;
          if (displayedImages[index]) {
            cells.push(
              <td key={j}>
                <img src={displayedImages[index].thumbnail} alt={`${index}`} onClick={()=>setSelectedImage(displayedImages[index])} />
              </td>
            );
          } else {
            cells.push(<td key={j}></td>);
          }
        }
        rows.push(<tr key={i}>{cells}</tr>);
      }
    
      return (
        <div className='desktopGrid desktop'>
            <table>
            <tbody>{rows}</tbody>
            </table>
        </div>
      );
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
};

export default DesktopGrid;
