const GOOGLE_DRIVE_URL_START = "https://www.googleapis.com/drive/v2/files?q=%27";
const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
const FOLDER_ID = "1390Z5G5e-mq7Ql0cTE1wfoY3B4Qkd8Yi";
const GOOGLE_API_KEY = "AIzaSyCjbTUyyGWb0c0EW_0UH1QW3c2C72ziYIU";

const GOOGLE_DRIVE_IMG_URL = "http://drive.google.com/uc?export=view&id=";

export const GetImages = async () => {
  let images = [];

  let nextPageToken = null;
  do {
    try {
      let url = GOOGLE_DRIVE_URL_START + FOLDER_ID + GOOGLE_DRIVE_URL_END + GOOGLE_API_KEY;
      if (nextPageToken) {
        url += "&pageToken=" + nextPageToken;
      }
      const request = await fetch(url);
      const response = await request.json();

      if (response && response.items && response.items.length > 0) {
        const imagesData = response.items;
        images.push(
          ...imagesData.map((item) => ({
            visualize: `${GOOGLE_DRIVE_IMG_URL}${item.id}`,
            thumbnail: item.thumbnailLink,
            download: item.downloadUrl,
          }))
        );
      }

      nextPageToken = response.nextPageToken;
    } catch (e) {
      console.log(e);
      break;
    }
  } while (nextPageToken);

  console.log(images);

  return images;
};
