import React from 'react';
import './Popup.css';

// UI elements
import closeBtn from '../../assets/1 Mobile/Pop Up/Btn Cerrar.png';
import title from '../../assets/1 Mobile/Pop Up/Titulo.png';
import desktopTitle from '../../assets/2 Web/Pop Up/Titulo.png';
import downloadBtn from '../../assets/1 Mobile/Pop Up/Btn Descargar Foto.png';

const Popup = ({selectedImage, closeAction}) => {
    const DownloadImage = () => {
        window.open(selectedImage.download, '_blank');
    }
    return (
        <div className='popup'>
            <img className='closeBtn' src={closeBtn} alt='close' onClick={() => {
                closeAction();
            }}/>
            <img src={title} alt='title' className='title mobile' />
            <img src={desktopTitle} alt='title' className='desktopTitle desktop' />
            <img src={selectedImage.visualize} alt='selected' className='selectedImage' />
            <img
                src={downloadBtn}
                alt='download'
                className='downloadBtn'
                onClick={DownloadImage}
            />
        </div>
    );
};

export default Popup;